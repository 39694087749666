<template>
  <v-app>
    <v-main
      :class="
        $vuetify.breakpoint.smAndDown ? 'primary pa-0 ma-0' : 'transparent'
      "
    >
      <v-container
        fluid
        style="height:100%"
      >
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'LoginLayout',
  }
</script>
